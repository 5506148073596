<template>
	<div class="tournament">

		<div class="tournament__body">
			<div class="tournament__body-item">

				<div class="tournament__img-wrap">
					<div v-if="image" class="tournament__img" :style="{
						'background-image': `url(${image})`
					}">
					</div>
				</div>

			</div>
			<div class="tournament__body-item">

				<div class="tournament__header">
					<div class="tournament__header-item tournament__header-item--title">
						<div class="tournament__title">{{title}}</div>
					</div>
					<div class="tournament__header-item">

						<template v-if="hasEnded">
							<Pill :parts="[
								'Tournament Ended',
							]"></Pill>
						</template>

						<template v-else-if="startingSoon">
							<Pill :parts="[
								'Starts In',
								'3H 24M 53S'
							]"></Pill>
						</template>

					</div>
				</div>

				<div class="tournament__info-title">Information</div>
				<div class="tournament__info">
					<div class="tournament__info-key">Type</div>
					<div class="tournament__info-spacer"></div>
					<div class="tournament__info-value">{{type}}</div>
				</div>
				<div class="tournament__info">
					<div class="tournament__info-key">Players</div>
					<div class="tournament__info-spacer"></div>
					<div class="tournament__info-value">{{players}}</div>
				</div>
				<div v-if="starts" class="tournament__info">
					<div class="tournament__info-key">Starts</div>
					<div class="tournament__info-spacer"></div>
					<div class="tournament__info-value ttu">{{starts}}</div>
				</div>
				<div v-if="ends" class="tournament__info">
					<div class="tournament__info-key">Ends</div>
					<div class="tournament__info-spacer"></div>
					<div class="tournament__info-value ttu">{{ends}}</div>
				</div>


				<div class="tournament__actions">
					<div class="tournament__actions-item">

						<Btn :disabled="!registered && !registrationOpen" :class="{
							'btn--font': (registered && !hasEnded && !inProgress)
						}" @click.native="!registered && !registrationOpen ? null : $emit('onAction')">
							<template v-if="hasEnded">Leaderboard</template>
							<template v-else-if="registered && inProgress">Play Now</template>
							<template v-else-if="registered && !inProgress">Un-register</template>
							<template v-else>Register</template>
						</Btn>

					</div>
					<div class="tournament__actions-item tournament__actions-item--note">

						<template v-if="registered && hasEnded">
							You already played, check if you won!
						</template>
						<template v-else-if="!registered && !registrationOpen">
							Registration opens: <span class="ttu">{{registrationStarts}}</span>
						</template>
						<template v-else-if="!inProgress">
							The Tournament has not yet begun
						</template>

					</div>
				</div>


			</div>
		</div>

	</div>
</template>


<script>
import moment from 'moment';

import Btn from '@/components/Btn'
import Pill from '@/components/Pill'

export default {
	name: 'Tournament',
	inheritAttrs: false,
	components: {
		Btn,
		Pill,
	},
	props: {
		registered: {
			type: Boolean,
			default: false,
		},
		title: String,
		image: String,
		type: String,
		players: String,
		startDate: String,
		endDate: String,
		registrationStartDate: String,
	},
	computed: {
		registrationOpen: function()
		{
			return !this.registrationStartDate || moment().isAfter(this.registrationStartDate);
		},
		startingSoon: function()
		{
			return moment().isBefore(this.startDate) && moment().add({days: 1}).isAfter(this.startDate);
		},
		inProgress: function()
		{
			return moment().isBetween(this.startDate, this.endDate);
		},
		hasEnded: function()
		{
			return moment().isAfter(this.endDate);
		},
		starts: function()
		{
			return this.startDate ? moment(this.startDate).format('DD MMM YYYY, HH:mm') : null;
		},
		ends: function()
		{
			return this.endDate ? moment(this.endDate).format('DD MMM YYYY, HH:mm') : null;
		},
		registrationStarts: function()
		{
			return this.registrationStartDate ? moment(this.registrationStartDate).format('DD MMM YYYY, HH:mm') : null;
		},
	}
}
</script>


<style lang="scss">

	@import "src/scss/tools";

	$c: '.tournament';
	#{$c}
	{
		position: relative;

		&--newly-registered
		{
			padding-bottom: 1px;

			&:before
			{
				content: '';
				display: block;
				position: absolute;
				top: -24px;
				left: -24px;
				right: -24px;
				bottom: -24px;
				border: 4px solid palette('blue');

				@include media(phab)
				{
					top: -24px;
					left: -12px;
					right: -12px;
					bottom: -24px;
					border-width: 2px;
				}
			}
		}

		.ttu
		{
			text-transform: uppercase;
		}

		&__body
		{
			display: flex;
			margin:0;
			padding:0;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			margin-left: -48px;
			margin-bottom: -24px;
		}

		&__body-item
		{
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: auto;
			width: 100%;
			padding-left: 48px;
			margin-bottom: 24px;

			@include media(not-phab)
			{
				width: 50%;
			}
		}

		&__img-wrap
		{
			position: relative;
			padding-top: 100% * (386/532);
			box-shadow: 0px 8px 16px rgba(0,0,0,0.3);
		}

		&__img
		{
			@extend %absolute-fill;
			@extend %bg-fill;
		}

		&__header
		{
			display: flex;
			margin:0;
			padding:0;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			margin-left: -16px;
			margin-bottom: 16px;
		}

		&__header-item
		{
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: auto;
			width: auto;
			padding-left: 16px;
			margin-bottom: 16px;

			&--title
			{
				flex-grow: 1;
			}
		}

		&__title
		{
			@extend %h2;
		}

		&__info-title
		{
			@extend %heading--bold;
			font-size: 20px;
			margin-bottom: 16px;
		}

		&__info
		{
			display: flex;
			margin:0;
			padding:0;
			flex-wrap: nowrap;
			align-items: flex-end;
			align-content: flex-end;
			margin-left: -5px;
			font-size: 14px;
			line-height: 1;
			color: palette('grey');

			&:not(:last-child)
			{
				margin-bottom: 24px;
			}
		}

		&__info-key,
		&__info-spacer,
		&__info-value
		{
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: auto;
			width: auto;
			padding-left: 5px;
		}

		&__info-spacer
		{
			flex-grow: 1;
			flex-shrink: 1;
			height: 1.5px;
			background-image: linear-gradient(to right, palette('grey') 0%, palette('grey') 33.33%, transparent 33.33%, transparent 100%);
			background-size: 4.5px 100%;
			background-repeat: repeat-x;
			background-clip: content-box;
		}

		&__info-value
		{
			@extend %font--bold;
			color: palette('font');
		}

		&__actions
		{
			display: flex;
			margin:0;
			padding:0;
			flex-wrap: nowrap;
			align-items: center;
			align-content: center;
			margin-left: -24px;
			margin-top: 40px;
		}

		&__actions-item
		{
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: auto;
			width: auto;
			padding-left: 24px;

			&--note
			{
				flex-shrink: 1;
				flex-grow: 1;
				color: palette('grey');
				font-size: 12px;
				line-height: 1.5;
			}
		}
	}

</style>
