<template>
	<div class="expose" v-bind:class="{
		'expose--active': active
	}" @click="close">
		<div class="expose__scroller" ref="exposeScroller">
			<div class="expose__container">
				<div class="expose__content" @click.stop>
					<img class="expose__close" src="/img/close.svg" @click="close">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Expose',
	components: {
	},
	data () {
		return {
		}
	},
	props: {
		active: Boolean
	},
	computed: {
	},
	methods: {
		close: function(evt)
		{
			if(this.active)
			{
				this.$emit('close');
			}
		}
	}
}
</script>

<style lang="scss">

	@import "src/scss/tools";

	$c: ".expose";
	#{$c}
	{
		position: fixed;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.7);
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.25s, visibility 0s 0.25s, z-index 0s 0.25s;

		@supports (backdrop-filter: blur(5px)) or (-webkit-backdrop-filter: blur(5px))
		{
			background-color: transparent;
		}


		&--active
		{
			z-index: 5000;
			opacity: 1;
			visibility: visible;
			transition: opacity 0.25s, visibility 0s, z-index 0s;
		}

		&__scroller
		{
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
		}

		&__container
		{
			position: relative;
			max-width: 100%;
			padding: 40px 20px;
			margin: auto; // Stop top overflow in centered flex container
		}

		&__content
		{
			position: relative;
			background-color: palette('white');
			padding: 32px 48px 48px;
			border: 1px solid palette('light-grey');
			box-shadow: 0 4px 8px rgba(0,0,0,0.05);
			max-width: 512px;
			text-align: center;

			@include media(phab)
			{
				padding: 32px 20px;
			}
		}

		&__close
		{
			cursor: pointer;
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 1;
		}
	}

</style>
