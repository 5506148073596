<template>
	<div class="home blur" :class="{
		'blur--active': exposeOpen
	}">


		<Banner background="/img/banner-bg.jpg">
			<div class="h1">Welcome to IGT<br />Tournaments, Mike</div>
		</Banner>


		<div class="tabs">
			<div class="tabs__nav">
				<div class="tabs__nav-item" @click="activeTab = 'registered'; resetRegisteredCounter()">

					<div class="tabs__nav-action" :class="{
						'tabs__nav-action--active': activeTab == 'registered'
					}">
						Registered <template v-if="refreshed && newRegistrationsCount > 0">({{newRegistrationsCount}})</template>
					</div>

				</div>
				<div class="tabs__nav-item" @click="activeTab = 'upcoming';">

					<div class="tabs__nav-action" :class="{
						'tabs__nav-action--active': activeTab == 'upcoming'
					}">
						Upcoming
					</div>

				</div>
				<div class="tabs__nav-item" @click="activeTab = 'leaderboards';">

					<div class="tabs__nav-action" :class="{
						'tabs__nav-action--active': activeTab == 'leaderboards'
					}">
						Leaderboards
					</div>

				</div>
			</div>


			<template v-if="!refreshed">
				<div class="home__no-tournaments">
					<div class="page-container">
						<div class="h3 home__no-tournaments-title">Don't see any tournaments?</div>
						<Btn class="btn--font" @click.native="refreshed = true">Refresh</Btn>
					</div>
				</div>
			</template>


			<template v-if="refreshed">
				<div class="tabs__content" :class="{
						'tabs__content--active': activeTab == 'registered'
					}">
					<div class="page-container">

						<div v-for="(tournament, i) in registeredTournaments" :key="tournament.id" class="home__tournament-spacer">
							<Tournament :class="{
								'tournament--newly-registered': tournament.newlyRegistered
							}" v-bind="tournament"></Tournament>
						</div>

					</div>
				</div>
				<div class="tabs__content" :class="{
						'tabs__content--active': activeTab == 'upcoming'
					}">
					<div class="page-container">

						<div v-for="(tournament, i) in upcomingTournaments" :key="tournament.id" class="home__tournament-spacer">

							<Tournament class="home__tournament" :class="{
								'home__tournament--registering': tournamentRegistering.includes(tournament.id) || tournamentRegistered.includes(tournament.id),
							}" v-bind="tournament" @onAction="acceptingTermsFor = tournament.id; termsExposeOpen = true;"></Tournament>

							<div v-if="tournamentRegistering.includes(tournament.id)" class="home__tournament-loader">
								<img src="/img/loader.svg" />
							</div>

							<div v-if="tournamentRegistered.includes(tournament.id)" class="home__tournament-alert">
								You’ve registed for this tournament!
							</div>
						</div>

					</div>
				</div>
				<div class="tabs__content" :class="{
						'tabs__content--active': activeTab == 'leaderboards'
					}">
					<div class="page-container">

						<div v-for="(tournament, i) in finishedTournaments" :key="tournament.id" class="home__tournament-spacer">
							<Tournament v-bind="tournament" @onAction="leaderboardExposeOpen = true"></Tournament>
						</div>

					</div>
				</div>
			</template>

		</div>


		<Expose :active="termsExposeOpen" @close="termsExposeOpen = false; acceptingTermsFor = null;">
			<div class="home__terms-title">Just before you register</div>
			<div class="home__terms-description">I accept to these terms and conditions quos tu tam crudelis fuisse, nihil oportere exquisitis rationibus confirmare, tantum. Filium morte multavit si sine metu degendae praesidia firmissima filium morte multavit si dolor sit amet.</div>
			<Btn @click.native="termsExposeOpen = false; registerForTournament(acceptingTermsFor); acceptingTermsFor = null;">Accept &amp; Continue</Btn>
		</Expose>


		<Expose :active="leaderboardExposeOpen" @close="leaderboardExposeOpen = false">
			<div class="home__leaderboard-title">Leaderboard</div>
			<div class="leaderboard">

				<div class="leaderboard__row leaderboard__row--winner">
					<div class="leaderboard__items">
						<div class="leaderboard__position">
							1
							<img class="leaderboard__icon" src="/img/crown.svg" />
						</div>
						<div class="leaderboard__person">Lyndsey</div>
						<div class="leaderboard__score">4340</div>
					</div>
				</div>

				<div class="leaderboard__row">
					<div class="leaderboard__items">
						<div class="leaderboard__position">2</div>
						<div class="leaderboard__person">Sofie Hubert</div>
						<div class="leaderboard__score">4220</div>
					</div>
				</div>

				<div class="leaderboard__row">
					<div class="leaderboard__items">
						<div class="leaderboard__position">3</div>
						<div class="leaderboard__person">Mike</div>
						<div class="leaderboard__score">4210</div>
					</div>
				</div>

				<div class="leaderboard__row">
					<div class="leaderboard__items">
						<div class="leaderboard__position">4</div>
						<div class="leaderboard__person">Adam</div>
						<div class="leaderboard__score">3900</div>
					</div>
				</div>

				<div class="leaderboard__row">
					<div class="leaderboard__items">
						<div class="leaderboard__position">5</div>
						<div class="leaderboard__person">Lucas Simões</div>
						<div class="leaderboard__score">3802</div>
					</div>
				</div>

				<div class="leaderboard__row leaderboard__row--user">
					<div class="leaderboard__items">
						<div class="leaderboard__position">324</div>
						<div class="leaderboard__person">Adam</div>
						<div class="leaderboard__score">41</div>
					</div>
				</div>

			</div>
		</Expose>


	</div>
</template>


<script>
import moment from 'moment'
import { remove, find } from 'lodash'

import Btn from '@/components/Btn.vue'
import Banner from '@/components/Banner.vue'
import Tournament from '@/components/Tournament.vue'
import Expose from '@/components/Expose.vue'

export default {
	name: 'Home',
	components: {
		Btn,
		Banner,
		Tournament,
		Expose,
	},
	data() {
		return {
			activeTab: 'upcoming',
			refreshed: false,
			newRegistrationsCount: 0,
			registeredTournaments: [
				{
					id: 67569543,
					registered: true,
					title: 'Tic Tac Toe',
					image: '/img/games/tic-tac-toe.jpg',
					type: 'Scheduled',
					players: '10 (10 still available)',
					startDate: moment().subtract({days: 1}).startOf('day').add({hours: 15, minutes: 30}).format(),
					endDate: moment().add({days: 1}).startOf('day').add({hours: 15, minutes: 30}).format(),
				},
			],
			upcomingTournaments: [
				{
					id: 44575684,
					title: 'Coin O Mania',
					image: '/img/games/coin-o-mania.jpg',
					type: 'Scheduled',
					players: '1000 (998 still available)',
					startDate: moment().startOf('hour').add({hours: 6}).format(),
					endDate: moment().startOf('hour').add({hours: 24}).format(),
				},
				{
					id: 675687458,
					title: 'Tic Tac Toe',
					image: '/img/games/tic-tac-toe.jpg',
					type: 'Scheduled',
					players: '10 (10 still available)',
					startDate: moment().startOf('hour').add({weeks: 1}).format(),
					endDate: moment().startOf('hour').add({weeks: 2}).format(),
					registrationStartDate: moment().startOf('hour').add({days: 1}).format(),
				},
				{
					id: 995323,
					title: 'Griffin\'s Throne',
					image: '/img/games/griffins-throne.jpg',
					type: 'Sit & Go',
					players: '3 (1 still available)',
				},
			],
			finishedTournaments: [
				{
					id: 5246733,
					registered: true,
					title: 'Coin O Mania',
					image: '/img/games/coin-o-mania.jpg',
					type: 'Scheduled',
					players: '1000 (998 still available)',
					startDate: moment().startOf('hour').subtract({weeks: 1}).format(),
					endDate: moment().startOf('hour').subtract({days: 1}).format(),
				},
			],
			acceptingTermsFor: null,
			tournamentRegistering: [],
			tournamentRegistered: [],
			termsExposeOpen: false,
			leaderboardExposeOpen: false,
		}
	},
	computed: {
		exposeOpen: function()
		{
			return this.termsExposeOpen || this.leaderboardExposeOpen;
		},
	},
	methods: {
		resetRegisteredCounter: function(timeout)
		{
			setTimeout(() =>
			{
				this.newRegistrationsCount = 0;
			}, timeout || 500);
		},
		registerForTournament: function(id)
		{
			this.tournamentRegistering.push(id);

			setTimeout(() =>
			{
				this.tournamentRegistered.push(id);
				remove(this.tournamentRegistering, (n) => n == id);

				setTimeout(() =>
				{
					remove(this.tournamentRegistered, (n) => n == id);

					var tournament = find(this.upcomingTournaments, {id: id});
					this.$set(tournament, 'registered', true);
					this.$set(tournament, 'newlyRegistered', true);
					this.registeredTournaments.unshift(tournament);
					remove(this.upcomingTournaments, (n) => n.id == id);
					this.newRegistrationsCount++;

				}, 2000);
			}, 2000);
		},
	}
}
</script>


<style lang="scss">

	@import "src/scss/tools";

	$c: ".home";
	#{$c}
	{
		&__no-tournaments
		{
			text-align: center;
			padding-top: 142px;
			padding-bottom: 80px;

			@include media(phab)
			{
				padding-top: 72px;
			}

			&-title
			{
				margin-bottom: 1em;
			}
		}

		&__tournament-spacer
		{
			position: relative;

			&:not(:last-child)
			{
				margin-bottom: 56px;
			}
		}

		&__tournament
		{
			transition: opacity 0.25s ease-out;

			&--registering
			{
				opacity: 0.25;
				pointer-events: none;
			}
		}

		&__tournament-loader
		{
			@extend %absolute-middle;

			img
			{
				animation: spin 1s linear infinite;
			}
		}

		&__tournament-alert
		{
			@extend %lato;
			@extend %absolute-middle;
			max-width: calc(100vw - 32px);
			background-color: palette('font');
			color: palette('white');
			text-align: center;
			padding: 14px 32px 18px;
			border-radius: 3px;
			line-height: 1.5;

			@include media(phab)
			{
				padding: 14px;
			}
		}

		&__terms
		{
			&-title
			{
				@extend %h3;
				margin-bottom: 24px;
			}

			&-description
			{
				margin-bottom: 32px;
			}
		}

		&__leaderboard
		{
			&-title
			{
				@extend %h3;
				margin-bottom: 24px;
			}
		}
	}

</style>
