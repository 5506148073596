<template>

	<div class="banner">
		<div class="banner__bg-wrap">
			<div v-if="background" class="banner__bg" :style="{
				'background-image': `url(${background})`
			}">
			</div>
		</div>

		<div class="banner__content">
			<slot></slot>
		</div>
	</div>

</template>


<script>
export default {
	name: 'Banner',
	props: {
		background: String,
	}
}
</script>


<style lang="scss">

	@import "src/scss/tools";

	$c: '.banner';
	#{$c}
	{
		position: relative;
		@extend %vertical-center;
		text-align: center;
		height: 280px;

		&__bg-wrap
		{
			@extend %absolute-fill;
			z-index: 0;

			&:after
			{
				content: '';
				display: block;
				@extend %absolute-fill;
				background-color: rgba(0,0,0,0.5);
			}
		}

		&__bg
		{
			@extend %absolute-fill;
			@extend %bg-fill;
		}

		&__content
		{
			position: relative;
			flex: auto 0 0;		// IE fix
			@extend %page-container;
			color: palette('white');
		}
	}

</style>
