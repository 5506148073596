<template>

	<div class="pill">
		<span v-for="(part, i) in parts" :key="i">{{part}}</span>
	</div>

</template>


<script>
export default {
	name: 'Pill',
	props: {
		parts: Array,
	}
}
</script>


<style lang="scss">

	@import "src/scss/tools";

	$c: '.pill';
	#{$c}
	{
		position: relative;
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: center;
		text-align: center;
		background-color: palette('font');
		color: palette('white');
		z-index: 0;			// Create a stacking context so :before can't go behind the bg
		overflow: hidden;			// Overflow context for effects (e.g. waves, ripples);
		-webkit-tap-highlight-color: transparent;
		@extend %h6;
		font-size: 12px;
		letter-spacing: 0.9px;
		padding: 0;
		border-width: 0;
		border-radius: 9999px;
		transition: all 0.2s ease-out;


		// Height
		&::after
		{
			content: "";
			width: 0;
			height: 20px;
		}

		& > *
		{
			position: relative;
			z-index: 2;
			padding: {
				top: 4px;
				bottom: 4px;
				left: 10px;
				right: 10px;
			}
			@extend %vertical-center;

			&:nth-child(2)
			{
				background-color: palette('orange');
			}
		}

		@at-root
		{
			a#{&}
			{
				text-decoration: none;
			}
		}
	}

</style>
