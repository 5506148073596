<template>

	<button class="btn">
		<span>
			<slot></slot>
		</span>
	</button>

</template>


<script>
export default {
	name: 'Btn',
}
</script>


<style lang="scss">

	@import "src/scss/tools";

	$c: '.btn';
	#{$c}
	{
		position: relative;
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: palette('blue');
		color: palette('white');
		z-index: 0;			// Create a stacking context so :before can't go behind the bg
		overflow: hidden;			// Overflow context for effects (e.g. waves, ripples);
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		@extend %h6;
		letter-spacing: 1px;
		min-width: 176px;
		padding: 0 24px;
		border-width: 0;
		border-radius: 9999px;
		transition: all 0.2s ease-out;

		// Effects
		&::before
		{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			pointer-events: none;
			transition: background-color 0.25s;
			background-color: rgba(0, 0, 0, 0);
		}

		&:hover:before
		{
			background-color: rgba(0, 0, 0, 0.1);
		}

		// Height
		&::after
		{
			content: "";
			width: 0;
			height: 48px;
		}

		& > *
		{
			position: relative;
			z-index: 2;
			padding: {
				top: 5px;
				bottom: 5px;
				left: 12px;
			}

			&:first-child { padding-left: 0; }
		}

		@at-root
		{
			a#{&}
			{
				text-decoration: none;
			}
		}

		&[disabled]
		{
			opacity: 50%;
			text-decoration: line-through;
			cursor: not-allowed;

			&:before
			{
				display: none;
			}
		}



		// Modifiers

		@each $name, $color in $palette
		{
			&--#{$name}
			{
				background-color: $color;
			}
		}

	}

</style>
