var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tournament"},[_c('div',{staticClass:"tournament__body"},[_c('div',{staticClass:"tournament__body-item"},[_c('div',{staticClass:"tournament__img-wrap"},[(_vm.image)?_c('div',{staticClass:"tournament__img",style:({
					'background-image': ("url(" + _vm.image + ")")
				})}):_vm._e()])]),_c('div',{staticClass:"tournament__body-item"},[_c('div',{staticClass:"tournament__header"},[_c('div',{staticClass:"tournament__header-item tournament__header-item--title"},[_c('div',{staticClass:"tournament__title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"tournament__header-item"},[(_vm.hasEnded)?[_c('Pill',{attrs:{"parts":[
							'Tournament Ended' ]}})]:(_vm.startingSoon)?[_c('Pill',{attrs:{"parts":[
							'Starts In',
							'3H 24M 53S'
						]}})]:_vm._e()],2)]),_c('div',{staticClass:"tournament__info-title"},[_vm._v("Information")]),_c('div',{staticClass:"tournament__info"},[_c('div',{staticClass:"tournament__info-key"},[_vm._v("Type")]),_c('div',{staticClass:"tournament__info-spacer"}),_c('div',{staticClass:"tournament__info-value"},[_vm._v(_vm._s(_vm.type))])]),_c('div',{staticClass:"tournament__info"},[_c('div',{staticClass:"tournament__info-key"},[_vm._v("Players")]),_c('div',{staticClass:"tournament__info-spacer"}),_c('div',{staticClass:"tournament__info-value"},[_vm._v(_vm._s(_vm.players))])]),(_vm.starts)?_c('div',{staticClass:"tournament__info"},[_c('div',{staticClass:"tournament__info-key"},[_vm._v("Starts")]),_c('div',{staticClass:"tournament__info-spacer"}),_c('div',{staticClass:"tournament__info-value ttu"},[_vm._v(_vm._s(_vm.starts))])]):_vm._e(),(_vm.ends)?_c('div',{staticClass:"tournament__info"},[_c('div',{staticClass:"tournament__info-key"},[_vm._v("Ends")]),_c('div',{staticClass:"tournament__info-spacer"}),_c('div',{staticClass:"tournament__info-value ttu"},[_vm._v(_vm._s(_vm.ends))])]):_vm._e(),_c('div',{staticClass:"tournament__actions"},[_c('div',{staticClass:"tournament__actions-item"},[_c('Btn',{class:{
						'btn--font': (_vm.registered && !_vm.hasEnded && !_vm.inProgress)
					},attrs:{"disabled":!_vm.registered && !_vm.registrationOpen},nativeOn:{"click":function($event){!_vm.registered && !_vm.registrationOpen ? null : _vm.$emit('onAction')}}},[(_vm.hasEnded)?[_vm._v("Leaderboard")]:(_vm.registered && _vm.inProgress)?[_vm._v("Play Now")]:(_vm.registered && !_vm.inProgress)?[_vm._v("Un-register")]:[_vm._v("Register")]],2)],1),_c('div',{staticClass:"tournament__actions-item tournament__actions-item--note"},[(_vm.registered && _vm.hasEnded)?[_vm._v(" You already played, check if you won! ")]:(!_vm.registered && !_vm.registrationOpen)?[_vm._v(" Registration opens: "),_c('span',{staticClass:"ttu"},[_vm._v(_vm._s(_vm.registrationStarts))])]:(!_vm.inProgress)?[_vm._v(" The Tournament has not yet begun ")]:_vm._e()],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }